// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/components/Loading.js\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/components/Loading.js");
  import.meta.hot.lastModified = "1714473812644.064";
}
// REMIX HMR END

import { Col, Button, Row, Container, Card, Form, Modal, Spinner } from "react-bootstrap";
export default function Loading() {
  return <>
			<Modal show={true} onHide={() => {}} animation={false}></Modal>
			
				<div style={{
      zIndex: 100000,
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      padding: "10px",
      borderRadius: "10%",
      background: "white"
    }}>
					<Spinner style={{
        color: "black",
        position: "relative",
        top: "2px"
      }} animation="border" role="status">
						<span className="visually-hidden">Loading...</span>
					</Spinner>
				</div>
			
		</>;
}
_c = Loading;
var _c;
$RefreshReg$(_c, "Loading");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;